import "./NotFoundPage.css"
import erro from "../assets/icons/error.png"

/* components */
import Header from '../components/layout/Header';
import Body from '../components/layout/Body';
import Footer from '../components/layout/Footer';

export default function NotFoundPage() {
    return (
        <div className="App">
            <Header />

            <Body>
                <div className="container erro">
                    <img src={erro} alt="Erro 404: Página não encontrada"></img>
                    <div className="description">
                        <h1>Erro 404</h1>
                        <h3>Página não encontrada</h3>
                    </div>
                </div>
            </Body>

            <Footer />
        </div>
    );
}