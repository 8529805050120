export default function useCommandsAccessibility({setModeContrast}) {
    function toggleDarkMode() {
        const root = document.documentElement;
        const appElements = document.getElementsByClassName('App');
        const currentMode = localStorage.getItem('color-mode');

        if (currentMode !== 'dark') {
            localStorage.setItem('color-mode', 'dark');
            root.classList.add('dark-mode');

            // Defina a cor de fundo para o modo escuro para todos os elementos com a classe 'App'
            Array.from(appElements).forEach(el => {
                el.style.color = '#FAFF00';
            });

            setModeContrast(true)

        } else {
            localStorage.removeItem('color-mode');
            root.classList.remove('dark-mode');

            // Remova a cor de fundo definida para todos os elementos com a classe 'App'
            Array.from(appElements).forEach(el => {
                el.style.color = '';
            });

            setModeContrast(false)
        }
    }

    function adjustFontSize(action) {
        const root = document.documentElement;
        const currentFontSize = parseFloat(window.getComputedStyle(root).fontSize);
    
        let newFontSize;
    
        switch(action) {
            case 'increase':
                newFontSize = currentFontSize + 1;
                if (newFontSize <= 17) {
                    root.style.fontSize = `${newFontSize}px`;
                }
                break;
            case 'decrease':
                newFontSize = currentFontSize - 1;
                if (newFontSize >= 12) {
                    root.style.fontSize = `${newFontSize}px`;
                }
                break;
            case 'reset':
                root.style.fontSize = ''; // Reseta para o valor definido no CSS
                break;
            default:
                break;
        }
    }    

    return { toggleDarkMode, adjustFontSize }
}