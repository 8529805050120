import { Link } from "react-router-dom"

import { extractPath, truncateText } from "../../../utilities/tratament_functions"

export default function CardPost({ card }) {
    return (
        <Link to={extractPath(card.link)} className="borde col-12">
            <div className={`card`}>
                <div className="info">
                    <div className="date">
                        {card.date}
                    </div>
                    <div className="categories">
                        <ul>
                            {card.categories.map(category => <li key={category.id}><p>{category}</p></li>)}
                        </ul>
                    </div>
                </div>
                <div className="content">
                    <div className="title">
                        <p>{card.title}</p>
                    </div>
                    <div className="description">
                        <p dangerouslySetInnerHTML={{ __html: card.content ? truncateText(card.content, 30) : "" }} />
                    </div>
                </div>
            </div>
        </Link>
    )
}