import { Link } from "react-router-dom";

export default function NavPage({ info }) {
    return (
        <div className="navpage">
            <div className="container">
                <div className="title col-12">
                    <p>{info.title}</p>
                </div>
                <nav className="nav col-12">
                    <ul className="menu">
                        <li className="item-menu">
                            <Link to="/portal"><p>Início</p></Link>
                        </li>
                        <li className="item-menu">
                            <p>{info.title}</p>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="container">
                <div className="description">
                    <p>{info.description}</p>
                </div>
            </div>
        </div>
    )
}