import React, { useEffect, useState, useCallback } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';

export default function PowerBIReport({ embedUrl }) {
    const [report, setReport] = useState(null);

    // Define os manipuladores de evento com useCallback para evitar recriação desnecessária
    const handleLoaded = useCallback(() => {
        console.log('Report loaded');
    }, []);

    const handleRendered = useCallback(() => {
        console.log('Report rendered');
    }, []);

    const handleError = useCallback((event) => {
        console.error(event.detail);
    }, []);

    // Mapa de eventos é estático e não precisa ser armazenado no estado
    const eventHandlersMap = new Map([
        ['loaded', handleLoaded],
        ['rendered', handleRendered],
        ['error', handleError]
    ]);

    // Configuração de incorporação
    const embedConfig = {
        type: 'report',
        embedUrl: embedUrl,
        settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
        }
    };

    // Obter o componente incorporado
    const getEmbeddedComponent = useCallback((embedObject) => {
        setReport(embedObject);
    }, []);

    // UseEffect para interações com o relatório depois que ele é carregado/renderizado
    useEffect(() => {
        if (report) {
            // Interaja com o relatório aqui
        }
    }, [report]);

    return (
        <PowerBIEmbed
            embedConfig={embedConfig}
            eventHandlers={eventHandlersMap}
            cssClassName={'powerbi-container'}
            getEmbeddedComponent={getEmbeddedComponent}
        />
    );
}
