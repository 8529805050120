import React from 'react';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';

export default function Carousel({ banners }) {
    function truncateDescription(description, wordLimit) {
        const words = description.split(" ");
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(" ") + " ...";
        }
        return description;
    }

    return (
        <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
        >
            {banners.map((banner) => (
                <SwiperSlide key={banner.id}>
                    <div className={`banner`} style={{ backgroundImage: `url("${banner.featured_image}")` }}>
                        <Link to={`${banner.guid ? banner.guid : ''}`}>
                            <div className='painel'>
                                <div className='content'>
                                    <div className='title'><p>{banner.title}</p></div>
                                    <div className='description'><p>{banner.content ? truncateDescription(banner.content, 30) : ""}</p></div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
