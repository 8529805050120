import { Link } from "react-router-dom";
import "./Body.css"

function Body({ children }) {
    return (
        <main className="body" aria-live="assertive" aria-atomic="true">
            {children}
            <div className="auxiliary-bar">
                <div className="services">
                    <nav className="menu">
                        <Link className='menu-item' to="https://apps.contabilidade.ro.gov.br/certidao" title="Emissão da Certidão Negativa com base no CPF.">
                            <i className='icon certidao' />
                            <p>Certidão Negativa</p>
                        </Link>
                        <Link className='menu-item' to="https://servicos.contabilidade.ro.gov.br/portal#" title="Relatórios de Gestão">
                            <i className='icon diveport' />
                            <p>DivePort</p>
                        </Link>
                        <Link className='menu-item' to="https://servicos.contabilidade.ro.gov.br/netdiver#" title="Relatórios Analíticos de Gestão">
                            <i className='icon netdiver' />
                            <p>NetDiver</p>
                        </Link>
                        <input type="checkbox" href="#" className="menu-open" id="menu-open" name="menu-open" />
                        <label className="menu-open-button" htmlFor="menu-open" title="Serviços">
                            <i className='icon services' />
                        </label>
                    </nav>
                </div>
                <div className="pesquisa">
                    <Link to="/portal/pesquisa" title='Botão de Pesquisa'><i className='icon pesquisa'></i><p>{"Pesquisar"}</p></Link>
                </div>
            </div>
        </main>
    )
}

export default Body;