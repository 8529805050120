import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Hooks
import useComunicationAPI from '../../../hooks/useComunicationAPI';

export default function CategoriesBar({ type }) {
    const [categories, setCategories] = useState([]);
    const { getCategories } = useComunicationAPI({ setCategories })

    useEffect(() => {
        getCategories({ type: type });
    }, []);

    return (
        <nav className="categories">
            <div className="title">
                <p>{"Categorias"}</p>
            </div>
            <ul className="menu">
                {categories.map((category) => {
                    return (
                        <li key={category.id} className="item-menu">
                            <Link to={`/portal/categorias/${category.name}`}>{category.name}</Link>
                        </li>
                    )
                })}
            </ul>
        </nav>
    );
}