import { Link } from "react-router-dom"
import { extractPath } from "../../../utilities/tratament_functions";

function cleanAndTruncateHtml(html, maxLength) {
    const plainText = new DOMParser().parseFromString(html, 'text/html').body.textContent || '';
    return plainText.length > maxLength ? plainText.substring(0, maxLength) + '...' : plainText;
}

export default function SearchCard({ posts }) {
    return (
        <ul>
            {posts.map((post, index) => (
                <li key={index}>
                    {post.type === 'archive' ? (
                        <a href={post.link} target="_blank" rel="noopener noreferrer">
                            <div className="info">
                                <div className="title">
                                    <h4>{post.title}</h4>

                                </div>
                                <div className="category">
                                    {post.categories.map((term, index) => {
                                        return <Link key={index} to={`/portal/categorias/${term}`}>{term}</Link>
                                    })}
                                </div>
                                <div className="date">
                                    {post.date}
                                </div>
                            </div>
                            <div className="content">Arquivo</div>
                        </a>
                    ) : (
                        <Link to={extractPath(post.link)}>
                            <div className="info">
                                <div className="title">
                                    <h4>{post.title}</h4>

                                </div>
                                <div className="category">
                                    {post.categories.map((term, index) => {
                                        return <Link key={index} to={`/portal/categorias/${term}`}>{term}</Link>
                                    })}
                                </div>
                                <div className="date">
                                    {post.date}
                                </div>
                            </div>
                            <div className="content">
                                {post.type === 'dashboard'
                                    ? 'Painel PowerBI'
                                    : post.type === 'post'
                                        ? cleanAndTruncateHtml(post.content, 150)
                                        : post.content
                                }
                            </div>
                        </Link>
                    )}
                </li>
            ))}
        </ul>
    )
}