import axios from "axios";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const API_DEVELOPMENT = process.env.REACT_APP_API_DEVELOPMENT;

const API = ENVIRONMENT === "development" ? API_DEVELOPMENT : 'https://alemdosnumeros.contabilidade.ro.gov.br/api/portal_contabilidade';

const api = axios.create({
  baseURL: API,
});

api.interceptors.response.use(
  (response) => {
    // Qualquer código de status que esteja na faixa de 2xx faz com que esta função seja acionada
    return response;
  },
  (error) => {
    // Qualquer código de status que caia fora da faixa de 2xx faz com que esta função seja acionada
    if (!error.response) {
      console.error("Não foi possível conectar à API.");
    }
    
    return new Promise(() => {});
  }
);

export default api;
