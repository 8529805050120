import React, { useEffect, useState } from "react";

//Arquivo de CSS
import "./Pesquisa.css"

// Componentes
import NavPage from "../../../components/pages/componentes/nav";
import Loading from "../../../components/pages/struture/Loading";
import SearchCard from "../../../components/pages/componentes/searchCard";

// Hoocks
import useComunicationAPI from '../../../hooks/useComunicationAPI'

const infoPage = {
    title: 'Buscar',
    description: ""
}

export default function Pesquisa() {
    const [query, setQuery] = useState('');
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [resultsTo, setResultTo] = useState()

    const { search } = useComunicationAPI({ setPosts, setNoResults, setIsLoading })

    useEffect(() => {
        // Defina a posição de rolagem para o topo da página
        window.scrollTo(0, 0);
    }, [posts]);

    const handleInputChange = (event) => {
        setQuery(event.target.value);
    };

    const handleSearch = async (event) => {
        event.preventDefault();
        setNoResults(false);
        setResultTo(query);

        try {
            search(query)
        } finally {
            setQuery('')
        }
    };

    return (
        <div className="workspace">
            <NavPage info={infoPage} />
            <div className="search container">
                <div className="search form">
                    <form onSubmit={handleSearch}>
                        <input
                            type="text"
                            placeholder="Pelo o que esta procurando"
                            name="pesquisa"
                            value={query}
                            onChange={handleInputChange}
                        />
                        <button type="submit">Buscar</button>
                    </form>
                </div>
                <div className="search results">
                    {isLoading ? (
                        <Loading />
                    ) : noResults ? (
                        <p>Nenhum resultado encontrado.</p>
                    ) : (
                        <div className="search">
                            {posts.length > 0 && (
                                <div className="title">
                                    <p>{"O resultado para"}<strong><p>{`"${resultsTo}":`}</p></strong></p>
                                </div>
                            )}
                            <SearchCard posts={posts} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}