import { useEffect, useState } from "react";

export default function FigureIcon({ imageContrast, legend, alt }) {

    return (
        <>
            <div className="figure">
                <div className="borde">
                    <i className={`icon`} aria-hidden="true"></i>
                </div>
                {legend ? (
                    <h2>{legend}</h2>
                ) : ("")}
            </div>
        </>
    )
}