import React, { useEffect, useRef } from "react";

// Component
import NavPage from "../../../components/pages/componentes/nav.js";
import CategoriesBar from "../../../components/pages/componentes/CategoriesBar.js";
import CardPost from "../../../components/pages/componentes/cardPost.js";

// CSS
import './PostPage.css';
import './CKEditor.css';

export default function PostPage({ posts, info, relatedPost }) {
    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            const oEmbedElements = contentRef.current.querySelectorAll('oembed[url]');

            oEmbedElements.forEach(el => {
                const url = el.getAttribute('url');
                // Extrai o ID do vídeo do YouTube. Essa regex precisa ser ajustada conforme o formato do URL que você espera.
                const youtubeIdMatch = url.match(/youtu\.be\/(.+)$/);
                if (youtubeIdMatch) {
                    const youtubeId = youtubeIdMatch[1];
                    const iframe = document.createElement('iframe');
                    iframe.setAttribute('src', `https://www.youtube.com/embed/${youtubeId}`);
                    iframe.setAttribute('frameborder', '0');
                    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
                    iframe.setAttribute('allowfullscreen', 'true');
                    iframe.style.width = '100%';
                    iframe.style.height = '32em';

                    el.parentNode.replaceChild(iframe, el);
                }
                // Adicione aqui o tratamento para outros tipos de oEmbed, se necessário.
            });
        }
    }, [posts])

    return (
        <div className="workspace">
            <NavPage info={info} />
            <div className="container posts">

                <div className={`post col-12 col-sm-${posts.categories.length === 0 ? 12 : 8}`}>
                    <div className="info">
                        <div className="date">
                            <p>{posts.date}</p>
                        </div>
                        <div className="categories">
                            <ul>
                                {posts.categories.map(category => <li><p>{category}</p></li>)}
                            </ul>
                        </div>
                    </div>
                    <div className="ck ck-editor__main">
                        <div ref={contentRef} className="ck-blurred ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline" dangerouslySetInnerHTML={{ __html: posts.content }} />
                    </div>
                </div>

                <div className="sidebar col-3">
                    {posts.categories.length === 0 ? (
                        <></>
                    ) : (
                        <>
                            <CategoriesBar type={posts.type} />
                            <div className="related">
                                <div className="title">
                                    <p>{"Conteúdos Relacionados"}</p>
                                </div>
                                <div className="cards">
                                    {relatedPost.map((post) => {
                                        return <CardPost card={post} key={post.id} />
                                    })}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}