import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// CSS and Icons
import "./Home.css";

// Hooks
import useComunicationAPI from "../../hooks/useComunicationAPI";

// Components
import Menus from "../../components/layout/components/menus";
import Carousel from "../../components/pages/componentes/carousel";
import NoticeBoard from "./components/NoticeBoard";
import Loading from "../../components/pages/struture/Loading";
import FigureIcon from "../../components/pages/componentes/figureIcon";

const groups = [
    {
        id: 2,
        name: 'Publicações',
        slug: 'publicacoes'
    }, {
        id: 3,
        name: 'Informativos',
        slug: 'informativos'
    }
];

function Home() {
    const [menuService, setMenuService] = useState([]);
    const [banners, setBanners] = useState([]);
    const [posts, setPosts] = useState([]);
    const [isloading, setIsLoading] = useState(false);
    const [info, setInfo] = useState({});

    const { fetchMenu, fetchBanners, groupPosts } = useComunicationAPI({ setBanners, setInfo, posts, setPosts, setIsLoading });

    useEffect(() => {
        // Defina a posição de rolagem para o topo da página
        window.scrollTo(0, 0);

        fetchBanners();
        fetchMenu({
            setMenu: setMenuService,
            name: "Menu de Serviços"
        });

        const slugs = []
        groups.forEach(async (group) => {
            slugs.push(group.slug)
        });

        groupPosts({ category: slugs, typePost: 'post' });
    }, []);

    return (
        <div className="workspace">
            <section className="paineis">
                <Carousel banners={banners} />
            </section>
            <section className="services">
                <FigureIcon
                    legend={"Paineis Contábeis"}
                    alt={"sessão de publicações e avisos"}
                />
                <div className="container">
                    <Menus menuItems={menuService} />
                </div>
            </section>
            <section className="noticeboard">
                <FigureIcon
                    legend={"Quadro de Avisos"}
                    alt={"sessão de publicações e avisos"}
                />
                {isloading ? (
                    <Loading />
                ) : (
                    <NoticeBoard
                        groups={groups}
                        posts={posts}
                    />
                )}
            </section>
            <section className="sobre">
                <div className="container">
                    <div className="card col-12 col-md-6 col-xl-4">
                        <div className="borde">
                            <div className="icons">
                                <i className="icon confiabilidade" aria-hidden="true" />
                            </div>
                            <div className="title">
                                <h3>Confiabilidade</h3>
                            </div>
                            <div className="content">
                                <p>O Portal é atualizado diariamente com os dados do Sistema Integrado de Planejamento e Gestão Fiscal (SIGEF), de onde são extraídas diretamente as informações da execução orçamentária. Outros sistemas completam a base de dados do portal: Sistema Integrado de Controle de Obras Públicas (SICOP), Sistema de Gestão Patrimonial (SIGEP), entre outros.</p>
                            </div>
                        </div>
                    </div>
                    <div className="card col-12 col-md-6 col-xl-4">
                        <div className="borde">
                            <div className="icons">
                                <i className="icon consultas" aria-hidden="true" />
                            </div>
                            <div className="title">
                                <h3>Consultas Personalizadas</h3>
                            </div>
                            <div className="content">
                                <p>Ao os painies, você pode montar a sua própria consulta, selecionando o período (mês e ano) e outros detalhamentos específicos da informação que precisa. Essas consultas trazem recursos gráficos para garantir uma análise mais rápida e visual.</p>
                            </div>
                        </div>
                    </div>
                    <div className="card col-12 col-md-6 col-xl-4">
                        <div className="borde">
                            <div className="icons">
                                <i className="icon faleconosco" aria-hidden="true" />
                            </div>
                            <div className="title">
                                <h3>Fale Conosco</h3>
                            </div>
                            <div className="content">
                                <p>Não encontrou o que procurava? Utilize o Fale Conosco e acesse os serviços da Ouvidoria Geral do Estado ou o serviço Deixe seu Recado. Consulte também a área de ajuda, que disponibiliza glossário, perguntas frequentes e outras formas de auxílio.</p>
                            </div>
                            <div className="guid">
                                <Link to={"/portal/contato"}>{"Acesse"}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home