import React, { useEffect } from "react";

//Arquivo de CSS
import "./Contato.css"

import NavPage from "../components/pages/componentes/nav"

const infoPage = {
    title: 'Fale Conosco',
    description: ""
};

export default function Contato() {

    useEffect(() => {
        // Defina a posição de rolagem para o topo da página
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="content">
            <NavPage info={infoPage} />
            <div className="contatos container">
                <div className="transparencia col-12 col-md-6">
                    <div className="card">
                        <div className="form">
                            <div className="title">
                                <p>{"Portal da Contabilidade Geral do Estado"}</p>
                            </div>
                            <div className="content">
                                <p>{"Deseja encaminhar sugestões, elogios, reclamações ou tirar dúvidas sobre o conteúdo e a navegação do Portal Além dos Números?"}</p>
                            </div>
                        </div>
                        <div className="time">
                            <div className="title">
                                <p>{"Horário de Atendimento:"}</p>
                            </div>
                            <div className="content">
                                <p>{"Dias úteis: 07:30 às 13:30 horas"}</p>
                            </div>
                        </div>
                        <div className="address">
                            <div className="title">
                                <p>{"Endereço"}</p>
                            </div>
                            <div className="content">
                                <p>{"Av. Farquar, 2986 - Palácio Rio Madeira - Edifício Rio Jamari, Curvo III, Térreo - Bairro Pedrinhas - CEP 76.801-470 - Porto Velho, RO"}</p>
                            </div>
                        </div>
                        <div className="contact">
                            <div className="title">
                                <p>{"Contato"}</p>
                            </div>
                            <div className="content">
                                <p>{"Outros meios de contato com a Contabilidade Geral do Estado"}</p>
                                <p><strong>{"Telefone: "}</strong>{"(69) 3211-6100 Ramal 1027"}</p>
                                <p><strong>{"E-mail: "}</strong>{"coges@contabilidade.ro.gov.br"}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ouvidoria col-12 col-md-6">
                    <div className="card">
                        <div className="form">
                            <div className="title">
                                <p>{"Ouvidoria Geral do Estado"}</p>
                            </div>
                            <div className="content">
                                <p>{"Caso você queira fazer uma denúncia, uma reclamação, solicitar documentos ou informações, dar sugestões ou elogios ao Governo de Rondônia e aos convênios federais firmados com o Estado, assim como acessar o Serviço de Informações ao Cidadão (SIC), entre em contato com a Ouvidoria Geral do Estado de Rondônia."}</p>
                            </div>
                        </div>
                        <div className="time">
                            <div className="title">
                                <p>{"Horário de Atendimento:"}</p>
                            </div>
                            <div className="content">
                                <p>{"Dias úteis: 07:30 às 13:30 horas"}</p>
                            </div>
                        </div>
                        <div className="address">
                            <div className="title">
                                <p>{"Endereço"}</p>
                            </div>
                            <div className="content">
                                <p>{"Av. Farquar, 2986 - Bairro Pedrinhas Palácio Rio Madeira, Edifício Rio Jamari, térreo Porto Velho, RO CEP 76820408"}</p>
                            </div>
                        </div>
                        <div className="contact">
                            <div className="title">
                                <p>{"Contato"}</p>
                            </div>
                            <div className="content">
                                <p>{"Outros meios de contato com a Ouvidoria Geral do Estado"}</p>
                                <p><strong>{"E-mail: "}</strong>{"ouvidoriaro@ouvidoria.ro.gov.br"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}