import NavPage from "../../../components/pages/componentes/nav.js";
import CategoriesBar from "../../../components/pages/componentes/CategoriesBar.js";
import CardPost from "../../../components/pages/componentes/cardPost.js";

import './ArchivePage.css';

export default function ArchivePage({ posts, info, relatedPost }) {
    return (<div className="workspace">
        <NavPage info={info} />
        <div className="container posts ">
            <div className="post col-8">
                <table>
                    <thead>
                        <tr>
                            <th className='title-column'>Nome</th>
                            <th>Data Publicação</th>
                            <th>Baixar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key={posts.id}>
                            <td className='post-table'>{posts.title}</td>
                            <td className='post-table'>{posts.date}</td>
                            <td>
                                <a href={posts.link} target="_blank" rel="noopener noreferrer">Ver</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="sidebar col-3">
                <CategoriesBar />
                <div className="related">
                    <div className="title">
                        <p>{"Conteúdos Relacionados"}</p>
                    </div>
                    <div className="cards">
                        {relatedPost.map((post) => {
                            return <CardPost card={post} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>)
}