import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "./Posts.css"

// Componentes
import NavPage from "../../components/pages/componentes/nav.js";
import Loading from "../../components/pages/struture/Loading.js";

import PostPage from "./post_page/PostPage.js";
import ArchivePage from "./archive_page/ArchivePage.js";
import DashboardPage from "./dashboard_page/DashboardPage.js";

// Hooks
import useComunicationAPI from '../../hooks/useComunicationAPI';

export default function Posts() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id_post = queryParams.get('p');

  const [posts, setPosts] = useState();
  const [relatedPost, setRelatedPost] = useState();
  const [info, setInfo] = useState({});

  const { fetchPosts } = useComunicationAPI({ setPosts, setInfo, setRelatedPost });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPosts(id_post);
  }, [id_post]);

  if (!posts) {
    return (
      <div className="workspace">
        <NavPage info={info} />
        <div className="container posts">
          <Loading />
        </div>
      </div>
    )
  } else if (posts && posts.type === 'dashboard') {
    return (<DashboardPage posts={posts} info={info} />)
  } else if (posts && posts.type === 'post') {
    return (<PostPage posts={posts} info={info} relatedPost={relatedPost} />)
  } else if (posts && posts.type === 'archive') {
    return (<ArchivePage posts={posts} info={info} relatedPost={relatedPost} />)
  }
}