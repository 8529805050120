import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// CSS and Images
import './NoticieBoard.css';

// Functions
import { extractPath, truncateText } from "../../../utilities/tratament_functions";

export default function NoticeBoard({ groups, posts }) {
    const [groupFocus, setGroupFocus] = useState([]);
    const [activeCard, setActiveCard] = useState('');

    useEffect(() => {
        handleGroup('Publicações', 'publicacoes');
    }, [])

    async function handleGroup(name, slug) {
        setGroupFocus(posts.filter(post => post.categories.includes(name)));
        setActiveCard(slug);
    };

    return (
        <>
            {posts.length > 0 ? (
                <div className="container board" >
                    <nav className="col-12 col-md-2">
                        <ul className="menu">
                            {groups.map(group =>
                                <li key={group.id} className="item-menu">
                                    <div className={`item ${activeCard === group.slug ? 'active' : ''}`}>
                                        <button onClick={() => handleGroup(group.name, group.slug)}>{group.name}</button>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </nav>
                    <div className="col-12 col-md-10 cards-board">
                        <div className="cards-borde">
                            {groupFocus.map((group, index) =>
                                <Link key={group.id} to={extractPath(group.link)} className="col-12 col-md-6 col-xl-4">
                                    <div className="card ">
                                        <div className="borde">
                                            <i className={`icon`} aria-hidden="true"></i>
                                        </div>
                                        <div className="title">
                                            {group.title}
                                        </div>
                                        <div className="content">
                                            <p dangerouslySetInnerHTML={{ __html: group.content ? truncateText(group.content, 25) : "" }} />
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    )
}
