import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//Arquivo de CSS
import "./Categories.css"

// Componentes
import NavPage from "../../../components/pages/componentes/nav";
import Loading from "../../../components/pages/struture/Loading";
import SearchCard from "../../../components/pages/componentes/searchCard";

// Hoocks
import useComunicationAPI from '../../../hooks/useComunicationAPI'

export default function Categories() {
    const { category } = useParams();
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [noResults, setNoResults] = useState(false);

    const { search } = useComunicationAPI({ setPosts, setNoResults, setIsLoading })

    useEffect(() => {
        window.scrollTo(0, 0);
        search(category);
    }, [category]);

    const infoPage = {
        title: category,
        description: ""
    };

    return (
        <div className="workspace">
            <NavPage info={infoPage} />
            <div className="categories container">
                {isLoading ? (
                    <Loading />
                ) : noResults ? (
                    <p>Nenhum resultado encontrado.</p>
                ) : (
                    <div className="results">
                        {posts.length > 0 && (
                            <SearchCard posts={posts} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}