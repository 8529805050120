import NavPage from "../../../components/pages/componentes/nav.js";
import PowerBIReport from "../../../components/pages/componentes/PowerBIRepost.js";

import './DashboardPage.css';

export default function DashboardPage({ posts, info }) {
    return (
        <div className="workspace">
            <NavPage info={info} />
            <div className="container posts">
                <div key={posts.id} className="content col-12">
                    <PowerBIReport embedUrl={posts.content} />
                </div>
            </div>
        </div>
    )
}