import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from './routes/Home/Home'
import Legislacao from "./routes/Legislacao";
import Contato from "./routes/Contato";
import Glossary from "./routes/Glossary";
import Perguntas from "./routes/Perguntas";

import Posts from "./routes/posts/Posts";
import Pesquisa from "./routes/posts/search_page/Pesquisa";
import Categories from "./routes/posts/categories_page/Categories";
import TableOfPosts from './routes/posts/table_page/TableOfPosts';

//Tratamento de erros
import NotFoundPage from './routes/NotFoundPage';

const router = createBrowserRouter([
  {
    path: "",
    element: <App />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/portal",
        element: <Home />
      },
      {
        path: "/portal/legislacao",
        element: <Legislacao />
      },
      {
        path: "/portal/contato",
        element: <Contato />
      },
      {
        path: "/portal/glossario",
        element: <Glossary />
      },
      {
        path: "/portal/perguntas_frequentes",
        element: <Perguntas />
      },
      {
        path: "/portal/pesquisa",
        element: <Pesquisa />
      },
      {
        path: "/portal/publicacao/",
        element: <Posts />
      },
      {
        path: "/portal/categorias/:category",
        element: <Categories />
      },
      {
        path: "/portal/grupo",
        element: <TableOfPosts />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

