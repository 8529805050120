import React, { useState, useEffect } from "react";
import "./Glossary.css"
import NavPage from "../components/pages/componentes/nav"

const infoPage = {
  title: 'Glossário',
  description: ""
}

const glossaryItems = [
  {
    palavra: 'Contabilidade pública',
    significado: 'Ramo da contabilidade que se concentra na gestão financeira e contábil dos recursos públicos de um governo ou entidade governamental.'
  },
  {
    palavra: 'Princípios Contábeis Aplicados ao Setor Público (PCASP)',
    significado: 'Conjunto de diretrizes e normas contábeis específicas para o setor público, que estabelecem os critérios para a elaboração das demonstrações financeiras.'
  },
  {
    palavra: 'Balanço patrimonial',
    significado: 'Demonstrativo contábil que apresenta a situação patrimonial e financeira de um governo ou entidade governamental em um determinado momento, destacando seus ativos, passivos e patrimônio líquido.'
  },
  {
    palavra: 'Demonstração de resultados',
    significado: 'Relatório que apresenta o desempenho financeiro de um governo ou entidade governamental durante um determinado período, evidenciando as receitas, despesas e resultado líquido.'
  },
  {
    palavra: 'Fluxo de caixa',
    significado: 'Demonstração contábil que registra as entradas e saídas de caixa de um governo ou entidade governamental em um determinado período, proporcionando uma visão do movimento financeiro.'
  },
  {
    palavra: 'Orçamento público',
    significado: 'Plano financeiro que estabelece as receitas esperadas e as despesas autorizadas de um governo para um período determinado, refletindo suas políticas e prioridades.'
  },
  {
    palavra: 'Receita pública',
    significado: 'Valores obtidos pelo governo por meio de impostos, taxas, contribuições, transferências e outras fontes, que são utilizados para financiar as despesas públicas.'
  },
  {
    palavra: 'Despesa pública',
    significado: 'Gastos realizados pelo governo para prover bens e serviços públicos, incluindo salários, investimentos, assistência social, educação, saúde, entre outros.'
  },
  {
    palavra: 'Controle interno',
    significado: 'Conjunto de políticas, procedimentos e mecanismos adotados pelo governo para garantir a eficiência, a eficácia e a conformidade com as normas na gestão dos recursos públicos.'
  },
  {
    palavra: 'Auditoria governamental',
    significado: 'Avaliação independente e sistemática das atividades financeiras e operacionais de um governo, com o objetivo de verificar a conformidade, a legalidade, a eficiência e a eficácia dos processos.'
  },
  {
    palavra: 'Transparência',
    significado: 'Princípio que preconiza a divulgação clara e acessível das informações financeiras e operacionais do governo, proporcionando aos cidadãos o conhecimento necessário para acompanhar e fiscalizar a administração pública.'
  },
  {
    palavra: 'Prestação de contas',
    significado: 'Processo pelo qual o governo é responsabilizado pela utilização adequada e eficiente dos recursos públicos, por meio da apresentação de relatórios financeiros e da demonstração dos resultados alcançados.'
  },
  {
    palavra: 'Controle externo',
    significado: 'Função exercida por órgãos de controle, como tribunais de contas, que fiscalizam a gestão dos recursos públicos, verificando a legalidade, a legitimidade e a economicidade dos atos administrativos.'
  },
  {
    palavra: 'Patrimônio público',
    significado: 'Conjunto de bens, direitos e obrigações pertencentes ao governo ou entidade governamental, incluindo imóveis, veículos, equipamentos e investimentos.'
  },
  {
    palavra: 'Ativo',
    significado: 'Recursos econômicos controlados pelo governo ou entidade governamental, com expectativa de benefícios futuros. Pode incluir dinheiro em caixa, investimentos, propriedades, entre outros.'
  },
  {
    palavra: 'Passivo',
    significado: 'Obrigações financeiras e compromissos do governo ou entidade governamental. Pode incluir empréstimos, dívidas, contas a pagar e obrigações trabalhistas.'
  },
  {
    palavra: 'Patrimônio líquido',
    significado: 'Diferença entre os ativos e os passivos de um governo ou entidade governamental. Representa o valor residual dos recursos após a satisfação das obrigações.'
  },
  {
    palavra: 'Plano de contas',
    significado: 'Estrutura contábil que organiza e classifica as contas utilizadas para registrar as transações e eventos financeiros de um governo, permitindo a análise e o controle das informações contábeis.'
  },
  {
    palavra: 'Depreciação',
    significado: 'Alocação sistemática do custo de um ativo ao longo de sua vida útil estimada. A depreciação é registrada como despesa ao longo do tempo para refletir a perda de valor do ativo.'
  },
  {
    palavra: 'Contabilidade por competência',
    significado: 'Método contábil que registra as receitas e despesas no período em que são geradas, independentemente do momento em que os fluxos de caixa ocorrem.'
  },
  {
    palavra: 'Contabilidade por caixa',
    significado: 'Método contábil que registra as receitas e despesas somente quando os fluxos de caixa correspondentes são recebidos ou pagos.'
  },
  {
    palavra: 'Contabilidade de custos',
    significado: 'Ramo da contabilidade que envolve o registro, a alocação e o controle dos custos de produção ou prestação de serviços de um governo ou entidade governamental.'
  },
  {
    palavra: 'Contabilidade de fundos',
    significado: 'Sistema contábil utilizado para controlar e relatar as transações e atividades financeiras de fundos específicos, como fundos fiduciários ou fundos especiais.'
  },
  {
    palavra: 'Contabilidade por fundos orçamentários',
    significado: 'Método contábil utilizado para registrar as transações relacionadas ao orçamento público, separando as receitas e despesas de acordo com suas categorias orçamentárias.'
  },
  {
    palavra: 'Tesouraria',
    significado: 'Setor responsável pela gestão dos recursos financeiros do governo, incluindo o controle de caixa, o gerenciamento de investimentos e a administração dos saldos bancários.'
  },
  {
    palavra: 'Análise financeira',
    significado: 'Processo de avaliação e interpretação das informações financeiras e contábeis com o objetivo de compreender a saúde financeira, a eficiência e a sustentabilidade do governo ou entidade governamental.'
  },
  {
    palavra: 'Custeio por atividade',
    significado: 'Método contábil utilizado para atribuir custos às atividades específicas realizadas pelo governo, fornecendo informações detalhadas sobre o consumo de recursos e os custos envolvidos.'
  },
  {
    palavra: 'Controle de estoque',
    significado: 'Processo de registro e controle dos itens de estoque do governo, como materiais, suprimentos ou equipamentos, para garantir uma gestão adequada dos recursos.'
  }
];

const Glossary = () => {
  useEffect(() => {
    // Defina a posição de rolagem para o topo da página
    window.scrollTo(0, 0);
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  // Filtra os itens do glossário com base no valor do filtro de busca
  const filteredItems = glossaryItems
  .filter((item) =>
    item.palavra.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.significado.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .sort((a, b) => a.palavra.localeCompare(b.palavra)); // Ordenar em ordem alfabética

  return (
    <div className="glossario-page">
      <NavPage info={infoPage} />
      <div className="container">
        <div className="glossario-filtro col-12">
          <input
            type="text"
            value={searchTerm}
            name="pesquisa"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Qual o termo que gostaria de se inteirar"
          />
        </div>
        <div className="glossario col-12">
          <ul className="conjunto">
            {filteredItems.map((item, index) => (
              <li key={index}>
                <div className="title">
                  <p>{item.palavra}</p>
                </div>
                <div className="content">
                  <p>{item.significado}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Glossary;