import api from "../utilities/api"

export default function useComunicationAPI({
    setCategories,
    setPosts,
    setInfo,
    setRelatedPost,
    setIsLoading,
    setNoResults,
    setBanners
}) {

    async function search(query) {
        setIsLoading(true);
        try {
            const response = await api.get(`/pesquisa/?search=${query}`);

            if (Array.isArray(response.data)) {
                if (response.data.length === 0) {
                    setNoResults(true);
                }
                setPosts(response.data);
            } else {
                setNoResults(true);
                setPosts([]);
            }
        } catch (error) {
            setNoResults(true);
            setPosts([]);
            return console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    async function getCategories({ type }) {
        try {
            const response = await api.get(`/categories/?type=${type}`);

            if (Array.isArray(response.data)) {
                if (response.data.length === 0) {
                    setNoResults(true);
                }
                setCategories(response.data);
            } else {
                setCategories([]);
            }
        } catch (error) {
            setNoResults(true);
            setCategories([]);
            return console.log(error)
        }
    };

    async function fetchPosts(id_post) {
        try {
            const response = await api.get(`posts/${id_post}`);
            if (response && response.data) {
                setPosts(response.data.post[0]);
                setRelatedPost(response.data.related);
                setInfo({ title: response.data.post[0].title });
            }
        } catch (error) {
            return console.error('Error fetching menu:', error);
        }
    };

    const fetchMenu = async ({ setMenu, name }) => {
        try {
            const response = await api.get(`menus/${name}`);
            if (response && response.data) {
                setMenu(response.data);
            }
        } catch (error) {
            return console.error('Error fetching menu:', error);
        }
    };

    const groupPosts = async ({ category, typePost, limitPage, page }) => {
        const group = { category, typePost }
        setIsLoading(true);

        try {
            const response = await api.post(`search/category/?limitPage=${limitPage}&page=${page}`, group);

            if (response.data.posts && response.data.posts.length > 0) {
                setPosts(response.data.posts);
                setInfo(response.data.category);
                setIsLoading(false);
            } else {
                setNoResults(true);
                setIsLoading(false);
            }
        } catch (error) {
            if (setIsLoading) { setIsLoading(false) };
            return console.log(error);
        }
    };

    const fetchBanners = async () => {
        try {
            const response = await api.get('banners');
            if (response && response.data) {
                setBanners(response.data.object);
            }
        } catch (error) {
            console.error('Error fetching menu:', error);
        }
    };

    return { search, fetchPosts, getCategories, fetchMenu, groupPosts, fetchBanners }
}