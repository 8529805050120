import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom"

// CSS
import "./Header.css";

// Images
import Logo from "../../assets/img/cogesicon.png";
import LogoContrast from "../../assets/img/cogesicon-contrastMode.png";

// Componentes
import Menu from "./components/menus";

// Hooks
import useComunicationAPI from '../../hooks/useComunicationAPI';
import useCommandsAccessibility from '../../hooks/useCommandsAccessibility';

export default function Header() {
    const [menu, setMenu] = useState([]);
    const [modeContrast, setModeContrast] = useState(false);

    const { fetchMenu } = useComunicationAPI({})
    const { toggleDarkMode, adjustFontSize } = useCommandsAccessibility({ setModeContrast })

    useEffect(() => {
        fetchMenu({ setMenu, name: "Menu do Cabeçalho" });
    }, []);

    return (
        <header>
            <div className="container">
                <div className="logo">
                    <Link to="/portal"><img src={!modeContrast ? Logo : LogoContrast} alt="Portal da Transparência da Contabilidade Geral do Estado" /></Link>
                </div>

                <div className='responsive-menu'>
                    <input type="checkbox" href="#" className="menu-open" id="responsive-menu-open" name="responsive-menu-open" />
                    <label className="menu-open-button" htmlFor="responsive-menu-open" title="Serviços">
                        <span />
                    </label>
                </div>

                <div className="menu">
                    <div className="links">
                        <nav>
                            <ul className="menu">
                                <li className="item-menu" title='Ver Legislação'>
                                    <Link to="/portal/legislacao" className='title'>Legislação</Link>
                                </li>
                                <li className="item-menu" title='Ver Glossário'>
                                    <Link to="/portal/glossario" className='title'>Glossário</Link>
                                </li>
                                <li className="item-menu" title='Ver Meios de Contato'>
                                    <Link to="/portal/contato" className='title'>Fale Conosco</Link>
                                </li>
                                <li className="item-menu" title='Quem somos'>
                                    <p className='title'>Quem Somos</p>

                                    <ul className='submenu'>
                                        <li className="item-menu" title=''>
                                            <Link to="portal/publicacao/?p=3142" className='title'>A COGES</Link>
                                        </li>
                                        <li className="item-menu" title=''>
                                            <Link to="portal/publicacao/?p=3121" className='title'>Quem é Quem</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className="acessibilidade">
                        {/* <a href='#' alt='Acessibilidade'>Acessibilidade:</a> */}
                        <button onClick={() => adjustFontSize('decrease')} title='Diminuir Fonte'><strong>A-</strong></button>
                        <button onClick={() => adjustFontSize('increase')} title='Almentar Fonte'><strong>A+</strong></button>
                        <button onClick={() => adjustFontSize('reset')} title='Redefinir Fonte'><strong>A</strong></button>
                        <button onClick={() => toggleDarkMode()} alt='modo de alto contraste' name='contrate' title='Modo de Alto Contraste'>
                            <i alt='code de contraste' />
                        </button>
                    </div>
                    <Menu menuItems={menu} isResponsive={true} />
                </div>

                <div className="pesquisa">
                    <Link to="/portal/pesquisa"><i className='icon pesquisa'></i><p>{"Pesquisar"}</p></Link>
                </div>
            </div>
        </header>
    )
}