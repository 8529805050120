import React, { useEffect } from "react";

//Arquivo de CSS
import "./Perguntas.css"

import NavPage from "../components/pages/componentes/nav"

const infoPage = {
    title: 'Perguntas Frequentes',
    description: "Apresenta um compilado de perguntas frequentes"
}


export default function Perguntas() {

    useEffect(() => {
        // Defina a posição de rolagem para o topo da página
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="timeline">
            <NavPage info={infoPage} />
            <div className="container"></div>
        </div>
    );
}