import {
  useNavigate,
  Outlet
}
  from "react-router-dom";
import { useEffect } from "react";

/* components */
import Header from './components/layout/Header';
import Body from './components/layout/Body';
import Footer from './components/layout/Footer';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/') {
      navigate('/portal');
    }

    // Aplica o modo escuro se for a preferência do usuário
    if (localStorage.getItem('color-mode') === 'dark') {
      document.documentElement.classList.add('dark-mode');
    }
    
  }, [])

  return (
    <div className="App">
      <Header />

      <Body>
        <Outlet />
      </Body>

      <Footer />
    </div>
  );
}
export default App;