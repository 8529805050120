import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { extractPath } from "../../../utilities/tratament_functions";
import classNames from 'classnames';

function Menu({ menuItems, isResponsive = false }) {
    const [activeItem, setActiveItem] = useState(null);
    const [overflowItems, setOverflowItems] = useState([]);
    const menuRef = useRef(null);
    const navRef = useRef(null);
    const itemsRef = useRef([]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setActiveItem(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const checkOverflow = () => {
            if (menuRef.current) {
                const menuBounds = menuRef.current.getBoundingClientRect();
                const newOverflowItems = itemsRef.current.map(ref => {
                    if (ref.current) {
                        const itemBounds = ref.current.getBoundingClientRect();
                        return itemBounds.right > menuBounds.right;
                    }
                    return false;
                });
                setOverflowItems(newOverflowItems);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [menuItems]);

    const handleItemClick = (id) => {
        setActiveItem((prevActiveItem) => (prevActiveItem === id ? null : id));
    };

    const renderSubmenu = (submenuItems, parent) => (
        <ul
            className="submenu"
            role="menu"
        >
            {submenuItems.map((subItem, index) => renderMenu(subItem, index, false, true, parent))}
        </ul>
    );

    const renderMenu = (menuItem, index, isTopLevel = false, isOverflow = false, parent = null) => {
        const { id, title, icon, link, submenu } = menuItem;

        const menuItemClass = classNames('item-menu', {
            'active': activeItem === id,
            'overflow': overflowItems[index]
        });

        if (isResponsive && isTopLevel && !isOverflow) {
            itemsRef.current[index] = React.createRef();
        }

        return (
            <li
                key={id}
                className={menuItemClass}
                role="menuitem"
                title={title}
                ref={isResponsive && isTopLevel && !isOverflow ? itemsRef.current[index] : null}
                onClick={() => handleItemClick(parent)}
                style={{ visibility: isOverflow ? 'visible' : overflowItems[index] ? 'hidden' : 'visible' }}
            >
                <div className="title">
                    <div className="borde">
                        <i className={`icon ${icon}`} aria-hidden="true"></i>
                    </div>
                    {link ? (
                        <Link to={extractPath(link)} tabIndex="0">{title}</Link>
                    ) : (
                        <button>{title}</button>
                    )}
                </div>
                {submenu && submenu.length > 0 && renderSubmenu(submenu, parent)}
            </li>
        );
    };

    const renderOverflowMenu = () => (
        <ul className="overflow-menu" role="menu">
            <li className="item-menu" role="menuitem">
                <input type="checkbox" href="#" className="menu-open" id="overflow-menu-open" name="overflow-menu-open" />
                <label className="menu-open-button" htmlFor="overflow-menu-open" title="Mais Opções de Menus">
                    <span />
                </label>
                <ul className="submenu" role="menu">
                    {menuItems.map((menuItem, index) => {
                        if (overflowItems[index]) {
                            return renderMenu(menuItem, index, true, true, menuItem.id);
                        }
                        return null;
                    })}
                </ul>
            </li>
        </ul>
    );

    return (
        <nav ref={navRef}>
            <ul className="menu" role="menu" ref={menuRef}>
                {menuItems.map((menuItem, index) => renderMenu(menuItem, index, true, false, menuItem.id))}
            </ul>
            {isResponsive & overflowItems.length > 1 ? renderOverflowMenu() : ''}
        </nav>
    );
}

export default Menu;
