import React, { useEffect } from "react";

//Arquivo de CSS
import "./Legislacao.css"

import NavPage from "../components/pages/componentes/nav"

const infoPage = {
    title: 'Legislação',
    description: "Apresenta a legislação sobre transparência pública e legislação contábil."
}


export default function Legislacao() {

    useEffect(() => {
        // Define a posição de rolagem para o topo da página
        window.scrollTo(0, 0);
    }, []);

    return (  
        <div className="legislacao">

            <NavPage info={infoPage} />

            <div className="container">
                <ul className="time-line">
                    <li className="year container">
                        <div className="title col-1">
                            <p>2017</p>
                        </div>
                        <ul className="list col-11">
                            <li className="marker container">
                                <div className="title col-12">
                                    <p>Lei nº 13.460, de 26 de junho de 2017</p>
                                </div>
                                <div className="description col-11">
                                    <p>Esta Lei estabelece normas básicas para participação, proteção e defesa dos direitos do usuário dos serviços públicos prestados direta ou indiretamente pela administração pública.</p>
                                </div>
                                <div className="col-1">
                                    <a className="button" href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2017/lei/l13460.htm" target="blank">Ver</a>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul className="time-line">
                    <li className="year container">
                        <div className="title col-1">
                            <p>2012</p>
                        </div>
                        <ul className="list col-11">
                            <li className="marker container">
                                <div className="title col-12">
                                    <p>Decreto nº 7.845, de 14 de Novembro de 2012</p>
                                </div>
                                <div className="description col-11">
                                    <p>Regulamenta procedimentos para credenciamento de segurança e tratamento de informação classificada em qualquer grau de sigilo, e dispõe sobre o Núcleo de Segurança e Credenciamento.</p>
                                </div>
                                <div className="col-1">
                                    <a className="button" href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2012/decreto/d7845.htm" target="blank">Ver</a>
                                </div>
                            </li>
                            <li className="marker container">
                                <div className="title col-12">
                                    <p>Decreto Federal Nº 7.724, de 16 de Maio de 2012</p>
                                </div>
                                <div className="description col-11">
                                    <p>Regulamenta a Lei no 12.527, de 18 de novembro de 2011, que dispõe sobre o acesso a informações previsto no inciso XXXIII do caput do art. 5o , no inciso II do § 3o do art. 37 e no § 2o do art. 216 da Constituição.</p>
                                </div>
                                <div className="col-1">
                                    <a className="button" href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2012/decreto/d7724.htm" target="blank">Ver</a>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul className="time-line">
                    <li className="year container">
                        <div className="title col-1">
                            <p>2011</p>
                        </div>
                        <ul className="list col-11">
                            <li className="marker container">
                                <div className="title col-12">
                                    <p>Lei Federal Nº 12.527, de 18 de Novembro de 2011</p>
                                </div>
                                <div className="description col-11">
                                    <p>Regula o acesso a informações previsto no inciso XXXIII do art. 5o, no inciso II do § 3o do art. 37 e no § 2o do art. 216 da Constituição Federal; altera a Lei no 8.112, de 11 de dezembro de 1990; revoga a Lei no 11.111, de 5 de maio de 2005, e dispositivos da Lei no 8.159, de 8 de janeiro de 1991; e dá outras providências.</p>
                                </div>
                                <div className="col-1">
                                    <a className="button" href="http://www.planalto.gov.br/ccivil_03/_Ato2011-2014/2011/Lei/L12527.htm" target="blank">Ver</a>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
}